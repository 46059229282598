.loader-2 {
    display: block;
      height: 80px;
      width: 80px;
      -webkit-animation: loader-2-1 3s linear infinite;
              animation: loader-2-1 3s linear infinite;
  }
  @-webkit-keyframes loader-2-1 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-1 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span {
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 80px;
      width: 80px;
      clip: rect(16px, 80px, 80px, 0);
      -webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
              animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-2 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-2 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span::before {
      content: "";
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 80px;
      width: 80px;
      border: 3px solid transparent;
      border-top: 3px solid #FFF;
      border-radius: 50%;
      -webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
              animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-3 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-3 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span::after {
      content: "";
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 80px;
      width: 80px;
      border: 3px solid #034991;
      border-radius: 50%;
  }
  

  .large-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 9999;
    background-color: 'rgba(0, 0, 0, 0.2)';
  }

  .small-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }