.properties-thumb-left-bottom {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.properties-thumb-left-top {
  position: absolute;
  top: 10px;
  left: 10px;
}

.ms-auto {
  cursor: pointer;
}

.available-span{
    margin: 0 0 0 auto;
}