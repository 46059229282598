.create-blog {
    label {
        font-size: 14px;
        // color: #212121;
        font-weight: 400;
    }

    .select-post-status {
        .status-lebel {
            font-size: 14px;
            // color: #212121;
            font-weight: 400;
        }
    }

    textarea {
        min-height: 200px;
        resize: none;
    }

    .ql-editor{
        height: 250px;  
    }
}