#FHL-Sidebar-menu {
    max-width: 300px;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999;

    .offcanvas-header {
        text-align: center;
        padding: 12px 20px;
        display: block;
        background-color: #fff;

        .logo img {
            max-width: 180px;
        }

        .btn-close {
            background-size: 13px;
            float: right;
            margin-top: 10px;
        }
    }

    .offcanvas-body {
        background-color: #034991 !important;
        padding: 0;
        height: 100%;
        overflow-y: auto;

        ul {
            width: 100%;
            padding: 0;

            li {
                list-style: none;

                a,
                .btn {
                    font-size: 14px;
                    color: #d5d5d5;
                    text-decoration: none;
                    display: block;
                    font-weight: normal;
                    padding: 8px 15px;
                    width: 100%;
                    border: 0;
                    border-radius: 0;
                    text-align: left;
                    border-left: 3px solid transparent;
                    position: relative;

                    &:hover {
                        background-color: #0b3c6e;
                        color: #fff;
                        border-left: 3px solid #fff;

                        .menus-arrow {
                            fill: #ffffff;
                        }
                    }

                    &.active {
                        background-color: #0b3c6e;
                        color: #fff;
                        border-left: 3px solid #fff;
                    }

                    .menus-arrow {
                        position: absolute;
                        right: 15px;
                        width: 24px;
                        height: 24px;
                        fill: #d5d5d5;
                        margin: 0;
                    }

                    .collapsed .menus-arrow {
                        transform: rotate(270deg);
                    }
                }

                .btn {
                    &.active {
                        background-color: #034991;

                        .menus-arrow {
                            fill: #fff;
                        }
                    }
                }

                svg,
                span {
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    font-size: 23px;
                }

            }

            ul {
                background-color: #0b3c6e;
                padding-left: 20px;

                li {
                    a {
                        &:hover {
                            background-color: #0b3c6e;
                            color: #fff;
                            border-left: 3px solid transparent;
                        }
                    }
                }
            }
        }
    }
}





// Header Style

header {
    background-color: #fff;

    .logo {
        width: 185px;
    }

    .dropdown:hover>.dropdown-menu {
        display: block;
    }

    nav {
        .nav-item {
            margin: 0px 8px;

            .nav-link {
                font-size: 16px;
                // color: #212121;
                font-weight: 500;
                padding: 10px 0;
                transition: all 0.5s;
                position: relative;

                &.active {
                    color: var(--primary-color);

                    &:before {
                        width: 100%;
                    }
                }

                &:after,
                &:before {
                    transition: all 0.5s;
                }

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 0%;
                    content: '.';
                    color: transparent;
                    background: var(--primary-color);
                    height: 2px;
                }

                &:hover {
                    color: var(--primary-color);

                    &:before {
                        width: 100%;
                    }
                }
            }

            .dropdown .dropdown-menu {
                .nav-link {
                    padding: 5px 0;

                    &:before {
                        right: auto;
                    }
                }
            }

            &.dropdown .nav-link:before {
                margin: initial;
            }
        }

    }

    .header-right {
        .btn {
            font-size: 16px;
            padding: 10px 20px;
            font-weight: 500;
        }
    }





    @media (max-width: 1300px) {

        nav {
            .nav-item {
                margin: 0px 6px;

                .nav-link {
                    font-size: 15px;
                }
            }
        }

        .header-right .btn {
            font-size: 15px;
        }
    }




    @media (max-width: 1199px) {
        nav {
            .nav-item {
                margin: 0px 30px;

                .nav-link {
                    font-size: 15px;
                }
            }
        }

        .navbar-toggler {
            border: 0;
            margin-right: 10px;
        }

        #navbarSupportedContent {
            position: fixed;
            left: 0;
            background: #edf6ff;
            width: 100%;
            top: 70px;
        }

        .header-right .btn {
            padding: 5px 10px;
        }




    }


    @media (max-width: 767px) {
        .logo {
            width: 100px;
        }

        .header-right .btn {
            font-size: 14px;

            svg {
                display: none;
            }
        }

        #navbarSupportedContent {
            top: 58px;
        }
    }

}

// End Header Style