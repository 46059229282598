.badge {
  font-size: 11px;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.admin-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.collapse{
  visibility: visible !important;
}

.dropdown-item{
  cursor: pointer;
}




.bootstrap-dropdown,
.bootstrap-btn:hover,
.bootstrap-toggle:hover {
  background: #ffff !important;
  border: none;
}
.bootstrap-dropdown,
.bootstrap-toggle {
  background: #ffff !important;
  border: none;
}

.bootstrap-btn {
  cursor: default !important;
}






@media (max-width: 767px) {
  .admin-img {
    width: 30px;
    height: 30px;
}
}