.layout {
  text-align: center;
  min-height: 50vh;
  justify-content: center;
  padding: 109px;
  h1 {
    font-size: 24px;
    color: #333;
  }
  p {
    font-size: 16px;
    // color: #777;
  }
}
