#Register-Now {
  background-image: url(../../../assets/images/register-now-bg.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;

  h1 {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    line-height: 46px;
    max-width: 800px;
    margin: 0 auto;
  }

  .btn {
    font-size: 18px;
    color: #034991;
    font-weight: 700;
    border-radius: 61px;
  }

  .cta-arrow {
    position: absolute;
    top: -81px;
    left: 175px;
  }
}





// Responsive CSS
@media (max-width: 767px) {
  #Register-Now {  
    h1 {
      font-size: 32px;
    }
  }
}
// End Responsive CSS 







/* Ellipse Styles */
// .ellipse1 {
//   position: absolute;
//   box-sizing: border-box;
//   width: 1060px;
//   height: 717px;
//   background-color: skyblue;

//   border-radius: 50%;
//   opacity: 0.3;
// }

// .ellipse2 {
//   box-sizing: border-box;

//   position: absolute;
//   width: 1060px;
//   height: 717px;
//   background-color: skyblue;

//   border-radius: 50%;
//   opacity: 0.2;
// }
/* Ellipse 12 */

// position: absolute;