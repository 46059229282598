
.profile {
    // h3 {
    //     font-size: 24px;
    //     color: #020000;
    //     font-weight: 600;
    // }
    
    .update-profile {
        .profile-img {
            width: 100px;
            min-width: 100px;
            height: 100px;
            border-radius: 100%;
            overflow: hidden;
        }
    }
}