// .saveButton{
//     background-color: var(--primary-color);
// }

// .deleteButton{
//     background-color: grey;
// }

.warn-popup {
    height: 18px;
    color: #a0a024;
    width: 18px;
    position: relative;
    top: -2px;
}