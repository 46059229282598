.properties {
    h2 {
        font-size: 28px;
        // color: #212121;
        font-weight: 700;
        margin-bottom: 8px;
    }

    p {
        font-size: 18px;
        // color: #818181;
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .slick-arrow {
        width: 40px;
        height: 40px;
        border: 1px solid #EEEEEE;
        background-color: #fff;
        border-radius: 100%;
        position: absolute;
        z-index: 9;

        &:hover {
            background-color: #def3ff;
        }

        &.slick-prev {
            left: -50px;
            background-image: url('../../../assets/images/arrow-left.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }

        &.slick-next {
            right: -50px;
            background-image: url('../../../assets/images/arrow-right.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }
    }




    .slick-initialized .slick-slide>div {
        height: 100%;
    }

    .slick-dots {
        bottom: -50px;

        li {
            margin: 0 3px;

            button:before {
                font-size: 8px;
            }
        }
    }


}













// .fhome{
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     margin-top: 20px;
//     padding-top: 10px;
//     padding-bottom: 10px;
// }


// .ftitle{
//   font-size: 30px;
//   font-weight: bold;
// }

// .farraw{
//     position: absolute;
//     right: 80px;
//     display:flex;
//     padding: 20px;

//     flex-direction: row;
// }
// .left-arraw{
// padding-right: 20px;
// }
// .right-arraw{
// padding-left: 20px;
// }

// .fhcards{
//   height: 400px;
//   width: 100%;
//   margin: 10px;
//   padding-top: 20px;
//   padding-left: 50px;
//   padding-right: 50px;
//   display: flex;
//   flex-direction: row;
// }
// .fcard{
//   height: 350px;
//   width: 250px;
//   background-color: red;
//   margin: 20px;
//   padding: 10px;
//   overflow: hidden;
//   border: 2px solid #ccc;
//   border-radius: 8px;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
// }

//   .card-content {
//     padding: 16px;

//   }

//   .card-content p {
//     margin-bottom: 8px;
//   }