.drop-zone {
  text-align: center;
  border: 1px solid #eee;
  border-radius: 8px;
  background: linear-gradient(180deg, #eeeeee, #ffffff);
  padding: 72px 15px;
  cursor: pointer;

  .drag-and-drop-title {
    font-size: 16px;
    // color: #818181;
    margin-top: 15px;
  }

  .file-size {
    font-size: 14px;
    margin: 0;
  }
}

.drop-zone.invalid-error {
  border: 1px solid #ff0000;
}

.drop-zone-section {
  .chop-off {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 144px;
    font-size: 14px;
  }

  ul {
    list-style-type: none;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }
}
