#Why-Choose-Us {
    .section-header {
        margin-bottom: 30px;

        h2 {
            font-size: 32px;
            // color: #212121;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            // color: #212121;
        }
    }

    .key-points {
        .key-points-icon {
            background-color: #E3F5F7;
            min-width: 48px;
            width: 48px;
            height: 48px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .key-points-details {
            h3 {
                font-size: 16px;
                // color: #212121;
                font-weight: 500;
            }

            p {
                font-size: 14px;
                // color: #818181;
                max-width: 500px;
            }
        }
    }

    .section-feature-img {
        border-radius: 40px;
        overflow: hidden;
    }

}





// Responsive CSS
@media (max-width: 767px) {
    #Why-Choose-Us {
        .section-header {    
            h2 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }
        }

        .key-points-details {
            h3 {
                font-size: 14px;
            }
        }    
    }
}
// End Responsive CSS 