// .MessageingContainer {
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   gap: 1rem;
//   align-items: center;
//   background-color: white;

//   .container {
//     height: 98vh;
//     width: 83vw;
//     background-color: whitesmoke;
//     display: grid;
//     grid-template-columns: 25% 75%;
//     border-radius: 2rem;
//     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

//     @media screen and (min-width: 720px) and (max-width: 1080px) {
//       grid-template-columns: 35% 65%;
//     }
//   }
// }

// Messages Style
.message {
  position: relative;
  list-style: none;
  margin-top: 10px;
  .contacts-list {
    background-color: #ffffff;
    padding: 20px;
    height: calc(100vh - 110px);
    overflow: auto;

    .search-icon {
      position: absolute;
      right: 10px;
      top: 12px;
    }

    .messages-user {
      position: relative;
      margin-bottom: 25px;

      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;

        // .user-status {
        //   width: 13px;
        //   height: 13px;
        //   display: block;
        //   border: 1px solid #fff;
        //   position: absolute;
        //   top: 30px;
        //   left: 41px;
        //   border-radius: 100%;

        //   &.live-status {
        //     background-color: #2ecc71;
        //   }

        //   &.busy-status {
        //     background-color: #e74c3c;
        //   }

        //   &.away-status {
        //     background-color: #f1c40f;
        //   }
        // }
      }

      .user-details {
        .user-name {
          font-size: 14px;
          font-weight: 600;
          // color: #1d1f2c;
        }

        .message-excerpt {
          font-size: 13px;
          color: #667085;
        }
      }

      .message-count {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background-color: #73ace6;
        color: #ffffff;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        font-weight: 700;
      }
    }
  }

  .spinner-loader {
    left: -50px !important;
  }

  .chat {
    height: calc(100vh - 258px);
    overflow: auto;
    padding-right: 15px;

    ul {
      li {
        width: 100%;
        max-width: 80%;
      }
    }

    .messages-user {
      position: relative;
      margin-bottom: 25px;

      .user-img {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;

        .user-status {
          width: 13px;
          height: 13px;
          display: block;
          border: 1px solid #fff;
          position: absolute;
          top: 32px;
          left: 41px;
          border-radius: 100%;

          &.live-status {
            background-color: #2ecc71;
          }

          &.busy-status {
            background-color: #e74c3c;
          }

          &.away-status {
            background-color: #f1c40f;
          }
        }
      }

      .user-details {
        .message-date-time {
          font-size: 14px;
          color: #667085;
        }

        .full-message {
          font-size: 14px;
          color: #484848;
          background-color: #f7f7f7;
          padding: 10px;
        }
      }
    }

    .sended {
      float: left;
    }
    .recieved {
      float: right;

      .user-details {
        .message-date-time {
          font-size: 14px;
          color: #667085;
        }

        .full-message {
          color: #484848;
          background-color: #eef1ff;
        }
      }

      .messages-user {
        float: right;
      }
    }
  }

  textarea {
    resize: none;
    height: 50px;
    background-color: #f7f7f7;
  }

  .sent-message-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0px 10px;
    font-size: 13px;
    height: 40px;
  }
}

// End Messages Style
