
.blog {
    background-color: #F9F9FC;
    // margin-top: 100px;
    padding: 60px 0px 60px;
    h1 {
        font-size: 32px;
        // color: #212121;
        font-weight: bold;
    }
    .sort-posts {
        label, select {
            font-weight: 500;
        }
    }
    .card {
        border-radius: 10px;
        border-color: #EEEEEE;
        overflow: hidden;
        height: 100%;
        padding-bottom: 50px;
        .card-thumbnail {
            width: 100%;
            max-height: 250px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .card-date {
            font-size: 14px;
            color: #818181;
        }
        .card-title {
            font-size: 22px;
            // color: #212121;
            font-weight: bold;
            margin: 5px 0;
        }
        .card-text {
            font-size: 16px;
            // color: #818181;
            line-height: 22px;
        }
        a {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #1E1D85;
            font-weight: bold;
            position: absolute;
            bottom: 15px;
            svg {
                fill: #1E1D85;
                margin-left: 10px;
            }
        }
    }
}