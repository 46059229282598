.payment {

    h5 {
        font-size: 24px;
        color: #020000;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        // color: #212121;
        font-weight: 400;
    }

    .subscription-card  {
        &.selected{
            border-color: #034991;
            .btn-outline-primary {
                color: #ffffff;
                background-color: #0D8DD8;
            }
        }

        &:hover {
            border-color: #034991;
        }
    }
}
