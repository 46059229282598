.pdf-container {
  padding: 50px;
  background-color: #dedede;
  // margin-top: 50px;
  padding-left: 20%;
  padding-right: 20%;

  .react-pdf__Page__canvas {
    margin-bottom: 15px;
  }

  .react-pdf__Page {
    .linkAnnotation {
      a {
        height: 20%;
        &:hover {
          background: none;
          box-shadow: none;
        }
      }
    }
  }
}

.pdf-popup-container {
  // padding: 50px;
  // background-color: #dedede;
  // margin-top: 50px;
  padding-left: 6%;
  // padding-right: 20%;

  .react-pdf__Page__canvas {
    margin-bottom: 15px;
  }

  .react-pdf__Page {
    .linkAnnotation {
      a {
        height: 20%;
        &:hover {
          background: none;
          box-shadow: none;
        }
      }
    }
  }
}