.details-page {
  .deatils-header {
    // margin-top: 85px;
    background-color: #f9f9fc;
    padding: 30px 0px;

    .details-left {
      h1.property-type {
        font-size: 36px;
        // color: #212121;
        font-weight: bold;
      }

      p.property-address {
        font-size: 16px;
        // color: #818181;
        margin-top: 10px;
      }

      .property-tags {
        padding-top: 10px;

        .btn {
          font-size: 12px;
          text-transform: uppercase;
          color: #fff;
          margin-right: 10px;
        }
      }
    }

    .details-right {
      .btn {
        font-size: 14px;
        // color: #212121;
        font-weight: 500;
      }

      .share-btn,
      .watchlist-btn {
        width: 40px;
        height: 40px;
      }
    }

    .tags {
      .properties-thumb-bottom {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        padding: 10px;
      }

      .thumb-label {
        font-size: 12px;
        color: #ffffff;
        font-weight: 600;
        padding: 3px 10px;
        margin: 0 3px;
        border-radius: 4px;
      }

      .feature-label {
        background: linear-gradient(120deg, rgb(0 0 0 / 20%), #35a6b8);
      }

      .new-label {
        background: linear-gradient(145deg, #79cedc, #2198ab);
      }

      .available-label {
        background: #ececec;
        // color: #212121;
        float: right;
      }

      .entire-unit-label {
        background: linear-gradient(90deg, rgb(0 0 0 / 20%), #97FF58);
      }

      .property-type-label {
        background: linear-gradient(70deg, #83D2FF, #00A3FF);
        float: left;
      }
    }
  }

  .property-deatils {
    padding: 30px 0;

    .details-sidebar {
      .contact-landlord {
        .landlord-img {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          overflow: hidden;
          margin: 0 auto;
        }

        .verified-status {
          font-size: 12px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          width: 90px;
          justify-content: center;
          line-height: 20px;
          position: absolute;
          right: 15px;
          top: 15px;
          background-color: #F44336;
          color: #fff;

          svg {
            fill: #fff;
            margin-left: 2px;
            width: 18px;
          }

          svg.verified {
            display: none;
          }

          svg.unverified {
            display: block;
          }

          &.active {
            background-color: #17c653;
            color: #fff;

            svg.verified {
              display: block;
            }

            svg.unverified {
              display: none;
            }
          }
        }

        .landlord-name {
          font-size: 20px;
          color: #000000;
          font-weight: 500;
          text-align: center;
          margin: 15px 0;

          span {
            font-size: 16px;
            color: #818181;
            font-weight: normal;
          }
        }

        .form-control {
          border-color: #eee;
          // color: #818181;
          font-size: 14px;
        }
      }

      .property-describe {
        margin-top: 20px;

        span {
          font-size: 16px;
          // color: #818181;
          padding: 2px 0;
          display: flex;

          span {
            // color: #000000;
          }
        }

        h4 {
          font-size: 14px;
          color: #020000;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
      }

      .btn {
        font-size: 17px;
        // text-transform: uppercase;
        width: 100%;
        padding: 10px 0;
      }
    }

    // h3 {
    //   font-size: 18px;
    //   color: #020000;
    //   font-weight: 500;
    //   margin-bottom: 15px;
    // }

    .units-property {
      .unit-preview {
        max-height: 180px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .unit-price {
        font-size: 14px;
        // color: #212121;
        font-weight: 500;
      }

      .unit-title {
        font-size: 14px;
        // color: #212121;
        font-weight: 500;
      }

      .unit-features {
        li {
          font-size: 13px;
          // color: #818181;
          display: flex;
          align-items: center;
          margin: 5px 0;

          svg {
            margin-right: 5px;
            position: relative;
            top: 1px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .property-description p {
      font-size: 16px;
      // color: #818181;
    }

    .property-overview {
      .property-overview-tile {
        padding: 30px 20px;
      }

      h4 {
        font-size: 14px;
        color: #020000;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 5px;
      }

      h6 {
        font-size: 13px;
        // color: #818181;
      }

      .col {
        padding: 0px 8px;
      }
    }

    .amenities li {
      font-size: 16px;
      // color: #818181;
      display: inline-block;
      width: 33.333%;
      margin-top: 12px;

      svg {
        margin-right: 15px;
      }
    }

    .property-reviews {
      .review-user-img {
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        overflow: hidden;
      }

      h6 {
        font-size: 16px;
        color: #212121;
        font-weight: 500;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        // color: #818181;
        line-height: 20px;
      }

      .btn {
        font-size: 14px;
        color: #212121;
      }

      .btn.active {
        background-color: #eeeeee;
        border: 0;
        font-weight: 500;
      }
    }
  }

  .rbc-btn-group {
    display: block !important;
    white-space: inherit !important;
  }

  .selected-room {
    border: 2px solid #034991;
    border-radius: 15px;
  }
}