.payment-container {
    padding-top: 7% !important;
    text-align: -webkit-center;
}

.paypal-button-container {
    width: max-content;
}

._failed {
    border-bottom: solid 4px red !important;
}

._failed i {
    color: red !important;
}

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    // color: #495057;
    font-weight: 500;
}

._dash-btn {
    border: none;
    padding: 4px 10px;
    color: #fff;
    background: #28a745;
    border-radius: 4px;
}

._dash-btn-fail {
    border: none;
    padding: 4px 10px;
    color: #fff;
    background: red;
    border-radius: 4px;
}














// Payment Success Page Style

.alert-box {
    max-width: 420px;
    margin: 100px auto;
    position: relative;

    &.success {
        border-bottom: 4px solid #198754 !important;

        svg {
            fill: #198754;
        }
    }


    &.failed {
        border-bottom: 4px solid #dc3545 !important;

        svg {
            fill: #dc3545;
        }
    }


    svg {
        width: 70px;
        height: 70px;
        fill: #000000;
        position: absolute;
        top: -38px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #fff;
        border-radius: 100%;
        padding: 5px;
        animation: zoom-in-zoom-out 1s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.2, 1.2);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    h5 {
        margin: 30px 0 40px;
        font-weight: normal;
        font-size: 24px;

        span {
            font-size: 15px;
            margin-top: 15px;
            display: block;
        }
    }
}






// End Payment Success Page Style