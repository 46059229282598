.registered-user {
  ul {
    margin: 0;
    padding: 0;

    li {
      font-size: 16px;
      color: #667085;
      font-weight: normal;
      list-style: none;
      margin-bottom: 10px;

      span {
        font-weight: 500;
        margin-right: 10px;
        min-width: 80px;
        display: inline-block;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .license-status {
    font-size: 13px;
    padding: 3px 5px;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: center;
    line-height: 20px;

    svg {
      fill: #fff;
      margin-right: 3px;
      width: 20px;
    }

    &.active {
      background-color: #17c653;
      color: #fff;
    }

    &.expired {
      background-color: #f8285a;
      color: #fff;
    }
  }
}

.update-license {
  //   position: absolute;
  right: 20px;
  top: 20px;
  background-color: #ebf1f6;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  svg {
    width: 20px;
    fill: #034991;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
