.listings-container {
  padding: 10px;
}

.listings-wrapper {
  /* Auto layout */
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // gap: 24px;

  // position: absolute;
  // height: 1236px;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
}

// @media screen and (max-width: 968px) {
//   .listings-wrapper {
//     grid-template-columns: 1fr 1fr;
//     margin-left: 20px;
//     margin-right: 20px;
//   }
// }

// @media screen and (max-width: 580px) {
//   .listings-wrapper {
//     grid-template-columns: 1fr;
//   }
// }




