.home {
  position: relative;
  .featured_section {
    min-height: 30rem;
    width: 100%;
    .loaderContainer {
      position: relative !important;
      .spinner-loader {
        position: relative !important;
        margin-top: 15rem !important;
      }
    }
  }
}
