// Blog Details Page Style
.set-prices {

    .set-prices-form {
        h5 {
            font-size: 24px;
            color: #020000;
            font-weight: 600;
        }

        .nav {
            .nav-item {
                margin: 0 5px;
                min-width: 128px;

                .nav-link {
                    background-color: #FAFAFA;
                    border: 1px solid #FAFAFA;
                    padding: 24px;
                    text-align: center;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #020000;
                    font-weight: 600;

                    svg {
                        display: block;
                        margin: 0 auto 20px;

                        path,
                        rect {
                            stroke: #323232;
                        }
                    }

                    &.active {
                        background-color: rgb(3 73 145 / 10%);
                        color: #034991;
                        border: 1px solid #034991;

                        path,
                        rect {
                            stroke: #034991;
                        }
                    }
                }
            }
        }
    }


    @media (max-width: 767px) {
        .set-prices-form {
            .nav {
                .nav-item {
                    min-width: 90px;
                    margin-bottom: 20px;

                    .nav-link {
                        padding: 10px;
                        svg {
                            margin: 0 auto 10px;
                        }
                    }
                }
            }
        }
    }


}

// End Blog Details Page Style