footer {
    padding: 50px 0 20px;
    border-top: 1px solid #eeeeee;
    h4 {
        font-size: 18px;
        // color: #212121;
        font-weight: 500px;
    }
    ul {
        padding: 0;
        li {
            list-style: none;
            a {
                font-size: 14px;
                // color: #818181;
                text-decoration: none;
                text-transform: capitalize;
            }
        }

        &.social-share {
            margin: 20px 0;
             li {
                display: inline-block;
                margin-right: 15px;
            }
        }        
    }
    .newsletter {
        h4 {
            font-size: 18px;
            // color: #212121;
            font-weight: 500;
        }
        p {
            font-size: 14px;
            // color: #818181;
        }
        input {
            font-size: 14px;
            color: #8F90A6;
            border-radius: 10px !important;
            height: 54px;
        }
        .btn {
            background-color: #74CBD9;
            position: absolute;
            border-radius: 8px !important;
            display: flex;
            align-items: center;
            top: 8px;
            right: 8px;
            width: 38px;
            height: 38px;
            padding: 0;
            z-index: 9;
        }
    }

    .copyright {
        p {
            font-size: 14px;
            // color: #212121;
        }
    }
}




// Responsive CSS
@media (max-width: 767px) {
    footer {
        padding: 50px 0 20px;
        h4 {
            font-size: 16px;
        }
        .newsletter {
            h4 {
                font-size: 16px;
            }
        }
    }
}
// End Responsive CSS 