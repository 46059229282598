#Update {
  .field-icon {
    min-width: 44px;
    width: 44px;
    height: 44px;
    background-color: #ebf1f6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    svg {
      width: 24px;
      height: 24px;
      fill: #034991;
    }
  }

  .field-value {
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
    // color: #1D1F2C;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.on-off-toggle {
  .toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    background-color: #c1c5c8;
    border-radius: 30px;
    cursor: pointer;
  }

  /* After slide changes */
  .toggle:after {
    content: '';
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 3px;
    left: 5px;
    transition: all 0.5s;
  }

  /* Checkbox checked effect */
  .checkbox:checked+.toggle::after {
    left: 26px;
  }

  /* Checkbox checked toggle label bg color */
  .checkbox:checked+.toggle {
    background-color: #034991;
  }

  /* Checkbox vanished */
  .checkbox {
    display: none;
  }
}

// .toggle-wrapper-email {
//   padding: 8px;
//   display: flex;
//   gap: 6px;

//   .toggle {
//     position: relative;
//     display: inline-block;
//     width: 80px;
//     height: 30px;
//     background-color: red;
//     border-radius: 30px;
//     border: 2px solid gray;
//   }

//   /* After slide changes */
//   .toggle:after {
//     content: '';
//     position: absolute;
//     width: 25px;
//     height: 25px;
//     border-radius: 50%;
//     background-color: gray;
//     top: 1px;
//     left: 1px;
//     transition: all 0.5s;
//   }

//   /* Checkbox checked effect */
//   .checkbox:checked+.toggle::after {
//     left: 49px;
//   }

//   /* Checkbox checked toggle label bg color */
//   .checkbox:checked+.toggle {
//     background-color: green;
//   }

//   /* Checkbox vanished */
//   .checkbox {
//     display: none;
//   }
// }