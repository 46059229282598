.about-us {
    section {
        .about-img {
            max-height: 450px;
        }
        h2 {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
        }
    }
}