.show_pdf_doc {
  color: #a7a7a7;
  cursor: pointer;
}
// div#listing_pdf {
//   display: inline-block;
//   width: 100%;
//   padding: 120px 0 0 0;
//   height: 100px;
//   overflow: hidden;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   background: url('../../../assets/images/pdf-svg.svg') center center no-repeat
//     #e4e4e4;
//   border-radius: 20px;
//   background-size: 60px 60px;
// }

.upload-pdf {
  position: relative;
  overflow: hidden;
}

.upload-pdf input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 0px;
}

.upload-pdf label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  cursor: pointer;
  color: #a7a7a7;
}

.upload-pdf label:hover {
  background-color: #eee;
}

.upload-pdf i {
  margin-right: 10px;
}

.eye_icon {
  height: 34px;
  width: 22px;
  margin-left: 10px;
  color: #6e6d6d;
  cursor: pointer;
}
