// Review Page Style
.review-page {
  margin-top: 30px;

  h3 {
    font-size: 18px;
    color: #020000;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .user-review {
    .review-user-img {
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      border: 1px solid #ccc;
      padding: 4px;
      background: #ffffff;
    }

    h6 {
      font-size: 18px;
      // color: #212121;
      font-weight: 500;
      margin-bottom: 0;

      a {
        color: #034991;
      }
    }

    p {
      font-size: 14px;
      // color: #818181;
      line-height: 20px;
      margin-top: 5px;
    }

    .review-date {
      font-size: 14px;
      color: #818181;
    }

    .review-action {
      margin-top: 20px;

      button {
        background-color: #ebf1f6;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
      }
    }
  }
}

// End Review Page Style





@media (max-width: 1199px) {
  .box-view {
    height: auto !important;
}
}