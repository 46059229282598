.paginate-container {
  border-radius: 0 0 8px 8px;
  border: 1px solid #e5e7eb;
  border-top: 0;
}

.pagination-container {
  display: flex;
  float: right;
  span {
    align-content: center;
    margin-right: 12px;
  }
  select {
    outline: 0px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: var(--primary-color) !important;
  }
  .pagination {
    margin-right: 20px;
    margin-bottom: 0px;
    .active > .page-link {
      color: var(--bs-pagination-active-color) !important;
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
    .page-item {
      .page-link {
        color: var(--primary-color);
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
