.custom-info-window {
  color: #333;
  font-family: Arial, sans-serif;
}

.custom-info-window h1 {
  font-size: 20px;
  margin: 0 0 5px 0;
  color: #000000;
}

.gm-style-iw-ch {
  padding-top: 0px !important;
  height: 2px !important;
  button {
    padding-top: 0px !important;
    height: 4px !important;
    width: auto !important;
  }
}
