.img-edit-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.rotate-90 {
  transform: rotate(90deg);
}
.flip-horizontal {
  transform: scaleX(-1);
}
.vertical-buttons {
  left: 10px;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}
.square-button {
  align-items: center;
  //   background: none;
  //   background: rgba(0, 0, 0, 0.4);
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  margin-top: 5px;
  transition: 'background 0.5s';
  width: 42px;
}
.preview_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
}
.icon_name div {
  font-size: 11px;
  font-family: math;
  // position: relative;
  // top: -6px;
}

@media (min-width: 1190px) {
  .image-editor .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: 70px;
    margin-left: auto;
    // width: 95%;
  }
}

@media (min-width: 1137px) and (max-width: 1190px) {
  .image-editor .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: 23px;
    margin-left: auto;
  }
}

@media (min-width: 800px) and (max-width: 1136px) {
  .image-editor .modal-dialog {
    width: 41rem;
    margin-left: 31%;
    margin-right: auto;
  }
}

// @media (min-width: 576px) {
//   .image-editor .modal-dialog {
//     max-width: var(--bs-modal-width);
//     margin-right: auto;
//     margin-left: auto;
//   }
// }
