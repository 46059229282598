.MessageContainer {
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.2rem;

    &-thumb {
      background-color: rgba(255, 255, 255, 0.224);
      width: 0.1rem;
      border-radius: 1rem;
    }
  }

  .message {
    display: flex;
    align-items: center;
    cursor: default;

    .content {
      max-width: 40%;
      overflow-wrap: break-word;
      padding: 0.3rem 1rem 0.3rem 1rem;
      font-size: 1.1rem;
      border-radius: 0.6rem;
      color: black;
    }
  }

  .sended {
    justify-content: flex-end;

    .content {
      background-color: rgba(79, 4, 255, 0.129);
    }
  }

  .recieved {
    justify-content: flex-start;

    .content {
      background-color: rgba(153, 0, 255, 0.125);
    }
  }
}
.date-banner {
  margin-bottom: 0;
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 12px 6px 12px;
  font-size: 12.5px;
  line-height: 21px;
  text-align: center;
}

.date_popup_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .date_popup {
    position: absolute;
    background: #fff;
    color: red;
    z-index: 1;
    width: 100px;
    text-align: center;
  }
}
.date-value {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #667085;
}