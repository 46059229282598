#About-Us {
    background-color: #EDF8FA;

    .achievement-count {
        .achievement {
            background-color: #fff;
            border-radius: 20px;
            padding: 30px;

            h2 {
                font-size: 48px;
                // color: #212121;
                font-weight: 700;
                margin-bottom: 0;
                margin-top: 10px;
            }

            p {
                font-size: 16px;
                // color: #7C7C7D;
                margin-bottom: 0;
            }
        }
    }

    h2 {
        font-size: 32px;
        // color: #212121;
        font-weight: 700;
    }

    p {
        font-size: 18px;
        // color: #212121;
    }
}




// Responsive CSS
@media (max-width: 767px) {
    #About-Us {    
        .achievement-count {
            .achievement {   
                padding: 20px; 
                h2 {
                    font-size: 24px;
                }
            }
        }
    
        h2 {
            font-size: 20px;
            margin-top: 50px;
        }
        p {
            font-size: 16px;
        }
    }
}
// End Responsive CSS 







// .a-main{
//     background-color: #d6ecf0;
//     width: 100%;
//     padding: 0px 0px 10px 0px;
// }

// .a-btn{
//     background-color: transparent;
//     width: 159px;
//     height: 54px;
//     border: 1px solid #1E1D85;
//     border-radius: 8px;
// }
// #col2{
//     padding-top: 150px;
// }

// .box{
//     height: 150px;
//     width: 150px;
//     margin: 40px 0 0 30px;
//     background-color: whitesmoke;
//     border-radius: 20px;
//     padding: 35px;
//     font-size: 40px;
//     font-weight: bold   ;
// }
// #col1{
//     padding-right: 0px;
// }
// #col1-a{
//     padding-top: 130px;
//     padding-left: 200px;
//     padding-right: 0;
// }