.pp-home{
    width: 100%;
    // background-color: gray;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
}

.pp-bar{
    width: 60%;
    padding-left: 100px;

}
.pp-title{
  font-size: 30px;
  font-weight: bold;
}

.pp-arraw{
    position: absolute;
    right: 80px;
    display:flex;
    padding: 20px;
    
    flex-direction: row;
}
.left-arraw{
padding-right: 20px;
}
.right-arraw{
padding-left: 20px;
}

.pp-cards{
  height: 450px;
  margin: 10px;
  width: 100%;
  padding-left: 50px;
  padding-right: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
}
.pp-card{
  height: 350px;
  width: 300px;
  margin-left: 30px;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.pp-card-img{
  height: 200px;
  width: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

  .card-content {
    padding: 16px;

  }
  
  .card-content p {
    margin-bottom: 8px;
  }
.icons{
  display: flex;
  flex-direction: row;
  margin: 5px;
}
.icons div{
  margin-left: 5px;
  margin-right: 5px;
}