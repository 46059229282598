.drop-zone-section {
  p {
    font-size: 14px;
    margin: 3px 0;
    word-break: break-word;
  }

  .upload-preview {
    max-height: 100px;
    position: relative;

    .upload-preview-remove {
      position: absolute;
      top: 4px;
      right: 8px;
      width: 26px;
      height: 26px;
      background: rgb(255 255 255 / 54%);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        fill: #034991;
      }
    }
  }
}

.form-textarea {
  min-height: 150px !important;
}

.edit-btn-listing {
  border: none;
  border-radius: 100%;
  background-color: #fff;
  z-index: 99;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
}

.pencil_icon {
  color: #0b65c0;
  background-color: #fff;
  position: relative;
  bottom: 2px;
}

.cancel_icon {
  color: #0b65c0;
  background-color: #fff;
  position: relative;
  bottom: 2px;
  height: 23px;
  width: 23px;
}

#create-listing,
#location,
#detailed-info,
#near-by,
#documentsTab {
  //padding-top: 15px;
  position: relative;
  //top: -70px;
}

// #near-by {
//   .btn-align {
//     align-content: flex-end;
//   }
// }
