.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 400px;
}

.paypal {
  width: 460px;
  z-index: 1;
}

.customized-button {
  /* Main properties */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  /* THE MOST IMPORTANT THING */
  pointer-events: none;

  /* Optional properties */
  font-family: 'Open Sans', sans-serif;
  height: 48px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #ff4c29;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: 300ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}



@media (max-width: 767px) {
  .container {
    min-width: 100%;
  }
}