// .ChatContainer{
//   padding-top: 0rem;
//   display: grid;
//   grid-template-rows: 10% 78% 12%;
//   gap: 0.1rem;
//   overflow: hidden;

//   @media screen and (min-width: 720px) and (max-width: 1080px) {
//     grid-template-rows: 10% 75% 15%;
//   }

//   .chat-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 2rem;
//     background-color: gray;

//     .user-details {
//       display: flex;
//       align-items: center;
//       gap: 1rem;
//       cursor: default;

//       .avatar {
//         img {
//           height: 3rem;
//         }
//       }

//       .name {
//         h3 {
//           color: black;
//         }
//       }
//     }
//   }
// }
.chat-user {
  background: #f7f7f7;
  // display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 70px;
  position: relative;
  /* left: -24px; */
  align-content: center;
  padding: 15px;
}

.text-bold {
  font-weight: 600;
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.user-status {
  width: 13px;
  height: 13px;
  display: block;
  border: 1px solid #fff;
  position: absolute;
  top: 28px;
  right: -5px;
  border-radius: 100%;

  &.live-status {
    background-color: #2ecc71;
  }

  &.busy-status {
    background-color: #e74c3c;
  }

  &.away-status {
    background-color: #f1c40f;
  }
}