.housing-request {
    // .banner {
    //     background-image: url('../../assets/housing-request/images/housing-request-banner.jpg');
    //     background-position: left center;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     height: 400px;
    //     overflow: hidden;
    //     position: relative;

    //     .banner-content {
    //         height: 100%;
    //         display: flex;

    //         &:before {
    //             content: "";
    //             background: rgb(0 0 0 / 10%);
    //             position: absolute;
    //             height: 100%;
    //             width: 100%;
    //         }

    //         h1 {
    //             font-size: 48px;
    //             color: #FFFFFF;
    //             font-weight: 700;
    //             line-height: 65px;
    //             max-width: 850px;
    //         }

    //         p {
    //             font-size: 18px;
    //             color: #FFFFFF;
    //             max-width: 700px;
    //         }
    //     }
    // }

    .requester {
        width: inherit;
    }

    .request-points {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                font-size: 20px;
                // color: #212121;
                margin-bottom: 11px;
                display: flex;

                svg {
                    width: 28px;
                    min-width: 28px;
                    height: 28px;
                    fill: #034991;
                    margin-right: 15px;
                }
            }
        }

        h3 {
            font-size: 32px;
            // color: #212121;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    #housing-request-form {
        h2 {
            font-size: 28px;
            // color: #212121;
            font-weight: 700;
            margin-bottom: 8px;
        }

        p {
            font-size: 18px;
            // color: #818181;
        }
    }
}





// Traveler Details Page Style

.traveler-details {
    ul li {
        list-style: none;
        margin-bottom: 15px;

        span {
            font-weight: 500;
            margin-right: 5px;
        }
    }
}

// Traveler Details Page Style