.home-hero {
    min-height: calc(100vh - 80px);
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url('../../../assets/images/hero-section-bg.jpg');
    padding: 100px 0;

    h1 {
        font-size: 55px;
        color: #fff;
        font-weight: 700;
        text-align: center;
    }

    h6 {
        font-size: 24px;
        color: #fff;
        font-weight: 500;
        margin-top: 30px;
        max-width: 350px;
        text-align: center;
        margin: 15px auto 0;
    }
}






@media (max-width: 991px) {
    .home-hero {
        background-color: #034991;
        background-image: none;
        padding: 50px 0;
        min-height: auto;

        h1 {
            font-size: 32px;
        }

        h6 {
            font-size: 18px;
            max-width: 300px;
        }
    }
}





// .div-2 {
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
//     position: relative;
//     display: flex;
//     min-height: 90vh;
//     width: 100%;
//     justify-content: center;
//     align-items: center;
// }

// @media (max-width: 991px) {
//     .div-2 {
//         max-width: 100%;
//         padding: 0 20px;
//     }
// }

// .img {
//     position: absolute;
//     inset: 0;
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//     object-position: center;
//     opacity: 0.7;
// }

// .div-3 {
//     position: relative;
//     display: flex;
//     width: 100%;
//     max-width: 1180px;
//     flex-direction: column;
//     align-items: flex-start;
//     // margin: 102px 0 5px;
// }

// @media (max-width: 991px) {
//     .div-3 {
//         max-width: 100%;
//     }
// }

// @media (max-width: 991px) {
//     .div-4 {
//         max-width: 100%;
//         font-size: 40px;
//         line-height: 50px;
//     }
// }