
.hero-home-search {
  background: rgb(0 0 0 / 21%);
  padding: 20px;
  border: 2px solid #c7c7c7;
  border-radius: 10px;
  margin-top: 50px;
  label {
    color: #fff;
  }
  .btn {
    min-width: 150px;
  }
}

.react-datepicker-wrapper {
  display: block !important;
}




// Responsive CSS
@media (max-width: 991px) {
  .hero-home-search {
    margin-top: 30px;
  }
}
// End Responsive CSS 





















// .div-5 {
//   color: #fff;
//   width: 440px;
//   font: 400 22px/36px Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// @media (max-width: 991px) {
//   .div-5 {
//     max-width: 80%;
//   }
// }

// .div-6 {
//   border-radius: 12px;
//   box-shadow: 0px 30px 60px -15px rgba(143, 144, 188, 0.15);
//   backdrop-filter: blur(59.400001525878906px);
//   border-color: rgba(255, 255, 255, 0.01);
//   border-style: solid;
//   border-width: 5px;
//   background-color: rgba(255, 255, 255, 0.55);
//   align-self: stretch;
//   padding: 1px 4px 1px 1px;
//   margin-top: 90px;
// }

// @media (max-width: 991px) {
//   .div-6 {
//     max-width: 80%;
//     padding-right: 20px;
//     margin-top: 40px;
//   }
// }

// .div-7 {
//   gap: 20px;
//   display: flex;
// }

// @media (max-width: 991px) {
//   .div-7 {
//     flex-direction: column;
//     align-items: stretch;
//     gap: 0px;
//   }
// }

// .column {
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 83%;
//   margin-left: 0px;
// }

// @media (max-width: 991px) {
//   .column {
//     width: 100%;
//   }
// }

// .MidSearchBar {
//   display: flex;
//   flex-grow: 1;
//   gap: 0px;

//   select {
//     background: transparent;
//     border: none;
//     outline: none;
//     margin-top: 10px;
//     padding-right: 50px;
//     font-size: medium;
//     font-weight: 600;
//   }
// }

// @media (max-width: 991px) {
//   .div-8 {
//     margin-top: 40px;
//     flex-wrap: wrap;
//   }
// }

// .div-9 {
//   border-right: 3px solid rgba(229, 231, 235, 1);
//   display: flex;
//   justify-content: space-between;
//   gap: 10px;
//   white-space: nowrap;
//   padding: 10px 20px;
// }

// @media (max-width: 991px) {
//   .div-9 {
//     white-space: initial;
//     padding: 0 15px;
//   }
// }

// .div-10 {
//   display: flex;
//   flex-direction: column;
// }

// @media (max-width: 991px) {
//   .div-10 {
//     white-space: initial;
//   }
// }

// .div-11 {
//   color: rgba(33, 33, 33, 0.5);
//   font: 400 14px/114% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// @media (max-width: 991px) {
//   .div-11 {
//     white-space: initial;
//   }
// }

// .div-12 {
//   margin-right: 80px;
//   color: #212121;
//   text-transform: capitalize;
//   margin-top: 18px;
//   font: 600 18px/133% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// .div-13 {
//   margin-bottom: 11px;
//   color: #212121;
//   text-align: center;
//   align-self: end;
//   margin-top: 35px;
//   font: 400 18px/100% Font Awesome 6 Pro, -apple-system, Roboto, Helvetica,
//     sans-serif;
// }

// .div-14 {
//   border-right: 3px solid rgba(229, 231, 235, 1);
//   display: flex;
//   justify-content: space-between;
//   gap: 20px;
//   white-space: nowrap;
//   padding: 12px 10px;
// }

// @media (max-width: 991px) {
//   .div-14 {
//     white-space: initial;
//     padding: 0 10px;
//   }
// }

// .div-15 {
//   display: flex;
//   flex-direction: column;
// }

// @media (max-width: 991px) {
//   .div-15 {
//     white-space: initial;
//   }
// }

// .div-16 {
//   color: rgba(33, 33, 33, 0.5);
//   font: 400 14px/114% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// .div-17 {
//   margin-right: 80px;
//   color: #212121;
//   text-transform: capitalize;
//   margin-top: 4px;
//   font-size: medium !important;
//   font: 600 18px/133% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// @media (max-width: 991px) {
//   .div-17 {
//     white-space: initial;
//   }
// }

// .div-18 {
//   margin-bottom: 11px;
//   color: #212121;
//   text-align: center;
//   align-self: end;
//   margin-top: 34px;
//   font: 400 18px/100% Font Awesome 6 Pro, -apple-system, Roboto, Helvetica,
//     sans-serif;
// }

// .div-19 {
//   display: flex;
//   justify-content: space-between;
//   gap: 5px;
//   padding: 12px 10px;
// }

// @media (max-width: 991px) {
//   .div-19 {
//     padding: 0 10px;
//   }
// }

// .div-20 {
//   display: flex;
//   flex-direction: column;
// }

// .div-21 {
//   color: rgba(33, 33, 33, 0.5);
//   font: 400 14px/114% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// .div-22 {
//   margin-right: 80px;
//   color: #212121;
//   text-transform: capitalize;
//   margin-top: 18px;
//   white-space: nowrap;
//   font: 600 18px/133% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// @media (max-width: 991px) {
//   .div-22 {
//     white-space: initial;
//   }
// }

// .div-23 {
//   margin-bottom: 11px;
//   color: #212121;
//   text-align: center;
//   align-self: end;
//   margin-top: 34px;
//   font: 400 18px/100% Font Awesome 6 Pro, -apple-system, Roboto, Helvetica,
//     sans-serif;
// }

// .column-2 {
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 17%;
// }

// @media (max-width: 991px) {
//   .column-2 {
//     width: 100%;
//   }
// }

// .div-24 {
//   display: flex;
//   gap: 12px;
//   align-self: stretch;
//   white-space: nowrap;
//   text-align: center;
//   margin: auto 0;
// }

// @media (max-width: 991px) {
//   .div-24 {
//     margin-top: 40px;
//     white-space: initial;
//   }
// }

// .div-25 {
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   background-color: rgba(255, 255, 255, 0.6);
//   color: #212121;
//   width: 30px;
//   height: 30px;
//   margin-top: 6px;
//   padding: 5px;
//   font: 400 18px/100% Font Awesome 6 Pro, -apple-system, Roboto, Helvetica,
//     sans-serif;
// }

// @media (max-width: 991px) {
//   .div-25 {
//     white-space: initial;
//   }
// }

// .div-26 {
//   justify-content: center;
//   border-radius: 8px;
//   display: absolute;
//   margin-left: 50px;
// }

// @media (max-width: 991px) {
//   .div-26 {
//     white-space: initial;
//     padding: 0 0px;
//   }
// }

// .div-27 {
//   flex-grow: 1;
//   margin: auto 0;
//   font: 400 18px/100% Font Awesome 6 Pro, -apple-system, Roboto, Helvetica,
//     sans-serif;
// }

// .div-28 {
//   flex-grow: 1;
//   font: 500 16px/150% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

#search-input-error {
  border: 2px solid black;
  outline-style: solid;
  outline-color: red;
}

// #date-input {
//   border: none;
//   outline: none;
//   background-color: transparent;
//   height: 30px;
//   width: 130px;
//   padding: 0;
//   margin: 0;
//   font: 500 16px/150% Inter, -apple-system, Roboto, Helvetica, sans-serif;
// }

// #Search-btn {
//   height: 44px;
//   width: 110px;
//   color: white;
//   background-color: #034991;
// }

// #idSearch-dropdown {
//   background-color: red;
//   color: red;
// }

// .pac-container:after {
//   background-image: none !important;
//   height: 0px;
//   padding: 0px;
//   margin: 0px;
// }