.stripe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;

  .stripe-button {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    background: #5f5b9b;
    border: none;
    color: #fff;
    b {
      font-family: cursive;
    }
  }
}
