.saved-search {
    margin-top: 25px;
      .table {
        font-size: 14px;
        vertical-align: middle;
        margin-bottom: 0 !important;
  
        thead {
          background-color: #f9f9fc;
  
          th {
            color: #4a4c56;
            font-weight: 500;
          }
        }
  
        tbody {
          td {
            color: #667085;
          }
          td:first-child  {
            font-weight: 500;
          }
        }
  
        .property-details {
          h5 {
            // color: #1d1f2c;
            font-weight: 500;
            margin-bottom: 3px;
          }
  
          p {
            // color: #667085;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
  }
  