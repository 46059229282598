.below-header {
    .renew-alert {
        font-size: 16px;
        color: #FC414D;
        font-weight: 500;
    }
}


.subscription {
    .subscription-card {
        border: 1px solid #EEEEEE;
        border-radius: 16px;
        background-color: #fff;
        text-align: center;
        padding: 50px 20px 20px;
        height: 100%;

        .plan-price {
            font-size: 48px;
            color: #034991;
            font-weight: 900;
            margin-bottom: 8px;
        }

        .plan-name {
            font-size: 20px;
            color: #000000;
            font-weight: 600;
        }

        ul {
            margin-top: 30px;
            padding: 0;

            li {
                font-size: 16px;
                // color: #212121;
                list-style: none;
                font-weight: 400;
                margin: 20px 0;
            }
        }

        .btn {
            text-transform: uppercase;
            border-radius: 8px;
            // margin-top: 20px;
        }
    }
}




@media (max-width: 767px) {
    .subscription {
        .subscription-card {
            margin-bottom: 20px;
            height: auto;
        }
    }
}