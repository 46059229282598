.services {
    .inner-banner {
        h1 {
            // font-size: 48px;
            // color: #FFFFFF;
            // font-weight: 700;
            // line-height: 65px;
            // border-image: none;

            // .FHL-txt {
            //     border-bottom: 15px solid transparent;
            //     border-image: url('../../assets/images/border-img-two.png') 30 stretch;
            // }

            // .beautiful-ways-txt {
            //     border-bottom: 16px solid transparent;
            //     border-image: url('../../assets/images/border-img-one.png') 30 stretch;
            // }
        }
    }

    .services-offered {
        h2 {
            font-size: 32px;
            // color: #212121;
            font-weight: 700;
            text-align: center;
            margin-bottom: 40px;
        }

        .service-type {
            background-color: #ddd;
            border-radius: 12px;
            padding: 50px 30px 40px;
            position: relative;
            overflow: hidden;
            height: 100%;

            h3 {
                font-size: 24px;
                // color: #212121;
                font-weight: 700;
                line-height: 36px;
                margin-top: 40px;
            }

            h3,
            svg {
                position: relative;
                z-index: 1;
            }

            &::after {
                content: "";
                position: absolute;
                right: -80px;
                top: 50px;
                width: 312px;
                height: 312px;
                background: #e1dfd9;
                border-radius: 100%;
            }

            &.property-management {
                background-color: #FEF9E4;

                &::after {
                    background: linear-gradient(146deg, #fff5c9, white);
                }
            }

            &.mortgage-service {
                background-color: #EFF4F2;

                &::after {
                    background: linear-gradient(146deg, #D4EEE4, white);
                }
            }

            &.consulting-service {
                background-color: #F4ECE0;

                &::after {
                    background: linear-gradient(146deg, #EBDEC9, white);
                }
            }

            &.home-buying {
                background-color: #FFEAFD;

                &::after {
                    background: linear-gradient(146deg, #FFD7FB, white);
                }
            }

            &.home-selling {
                background-color: #E4FCFE;

                &::after {
                    background: linear-gradient(146deg, #CDF4F7, white);
                }
            }

            &.escrow-services {
                background-color: #E9E8FF;

                &::after {
                    background: linear-gradient(146deg, #D6D4FF, white);
                }
            }
        }
    }

}