.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}

.slick-arrow {
  color: var(--primary-color);
  background-color: var(--primary-color);
  &:hover {
    color: var(--primary-color);
    background-color: var(--primary-color);
  }
}
