.blog-details {
    background-color: #F9F9FC;
    padding: 60px 0px 60px;

    .post-preview {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    h1 {
        font-size: 24px;
        // color: #212121;
        font-weight: bold;        
    }

    h2 {
        font-size: 22px;
        // color: #212121;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    p {
        font-size: 18px;
        // color: #818181;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .blog-meta {
        .publisher-img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
        }
        ul {
            li {
                font-size: 16px;
                color: #818181;
                display: inline-block;
                border-left: 1px solid #818181;
                padding: 0 10px;
                line-height: normal;
                
                &:first-child {
                    padding-left: 0;
                    border-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .social-share {
        float: right;
        .btn-group {
            border-radius: 61px;
            overflow: hidden;
            border: 1px solid #EEEEEE;
        }
    }

    .blog-img{
        height:450px;
        width:100%;
    }
}