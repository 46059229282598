.earning-overview {
  // margin-bottom: 24px;
  p {
    font-size: 16px;
    // color: #777980;
    font-weight: 500;
  }
  h2 {
    font-size: 32px;
    // color: #1D1F2C;
    font-weight: 600;
  }
}

.no-msg{
  color: #ababab;
}

.statistic-indicator {
  display: flex;
  gap: 20px;
  p {
    font-size: 12px;
    // color: #667085;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;

    .indicator-circle {
      width: 10px;
      height: 10px;
      border-radius: 100%;

      &.sales {
        background-color: #768aff;
      }
      &.rent {
        background-color: #ffb524;
      }
    }
  }
}

.messages {
  position: relative;

  .messages-user {
    position: relative;
    margin-bottom: 25px;

    .user-img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
    }

    .user-details {
      .user-name {
        font-size: 14px;
        font-weight: 600;
        // color: #1D1F2C;
      }

      .message-excerpt {
        font-size: 13px;
        color: #667085;
      }
    }

    .message-count {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #73ace6;
      color: #ffffff;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      font-weight: 700;
    }
  }

  .view-all-messages {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  .blur {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
  .btn {
    font-size: 13px;
    color: #034991;
    font-weight: 500;
    border-radius: 66px;
    padding: 7px 20px;
  }
}
.plus-icon {
  position: relative;
  top: -1px;
  font-weight: 800;
}
.user-auto-status {
  position: absolute !important;
  left: 33px !important;
  height: 10px !important;
  width: 10px !important;
}

.table {
  font-size: 14px;
  vertical-align: middle;
  margin-bottom: 0 !important;
  min-width: 1110px;

  thead {
    background-color: #f9f9fc;

    th {
      color: #4a4c56;
      font-weight: 500;
    }
  }

  tbody {
    td {
      color: #667085;
      font-weight: 500;
    }
    tr{
        cursor: pointer;
    }
  }

  .property-details {
    width: 100%;

    h5 {
      // color: #1d1f2c;
      font-weight: 500;
      margin-bottom: 3px;
      font-size: inherit;
    }

    p {
      // color: #667085;
      font-weight: 500;
      font-size: 12px;
      margin: 0;
    }
  }

  .status {
    padding: 4px 12px;
    border-radius: 8px;

    &.for-inactive {
      background-color: #fff0ea;
      color: #f86624;
    }

    &.for-rent {
      background-color: #eaf8ff;
      color: #2bb2fe;
    }

    &.for-active {
      background-color: #e9faf7;
      color: #1a9882;
    }

    &.for-sold {
      background-color: #feecee;
      color: #eb3d4d;
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 0;

    svg {
      width: 18px;
      height: 18px;
      fill: #858d9d;
    }
  }

  .property-listing-preview {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 8px;
    overflow: hidden;
  }
}
